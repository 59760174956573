// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import EmvTerminal from '@ticketmaster/tm1pos-web-shared/components/EmvTerminal';
import LoadingComponent from '@ticketmaster/tm1pos-web-shared/components/LoadingComponent';
import {
  selectEmvOperationInProgress,
  selectEmvPaymentCancelInProgress,
  selectEmvPaymentInProgress,
  selectEmvPaymentReverseInProgress,
  selectEmvPaymentStartInProgress,
} from '@ticketmaster/tm1pos-web-shared/payment/emvPaymentModule-selectors';
import {
  cancelEmvTransaction,
  clearEmvTransaction,
} from '@ticketmaster/tm1pos-web-shared/payment/emvPaymentModule-slice';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { clearCartLocation } from '../../middleware/mixpanel-constants';
import { clearCart } from '../App/actions';
import {
  selectCartBySeatBlocks,
  selectcheckoutCart,
  selectRemovingSeatPlacedId,
  selectSuccessOrder,
} from '../App/selectors';
import { deselectPlaceToUnReserve } from '../EventDetailPage/actions';
import { selectIsOrderDeleteInProgress, selectOrderIdToRetry } from '../EventDetailPage/selectors/main';
import { selectIsDeselectingSeat } from '../EventDetailPage/selectors/seatStatus';
import { CheckoutHeader } from './components/checkout-header/checkout-header';
import CheckoutSummary from './components/CheckoutSummary';
import EmptyCart from './components/EmptyCart';
import Form from './components/Form';
import { checkout } from './components/Form/actions';
import { selectCheckoutInProgress } from './components/Form/selectors';
import OrderConfirmation from './components/order-confirmation/order-confirmation';
import { PurchaseLoadingGear } from './components/purchase-loading-gear/purchase-loading-gear';
import messages from './messages';
import { selectIfShowEMVLoading } from './selectors';
import './styles.scss';

export class Checkout extends PureComponent {
  onClearCart = () => {
    const { dispatch } = this.props;
    const clearPlace = clearCartLocation.CART;
    dispatch(clearCart(clearPlace));
  };

  onFormSubmit = () => {
    this.props.dispatch(clearEmvTransaction());
    this.props.dispatch(checkout());
  };

  onCancelEmvPayment = () => {
    this.props.dispatch(cancelEmvTransaction());
  };

  onDeselectSeat = (placeId, generalAdmission, section, isBestAvailable) => {
    this.props.dispatch(deselectPlaceToUnReserve({ place: { placeId, generalAdmission, section, isBestAvailable } }));
  };

  get checkoutHeader() {
    const { handleClickCart } = this.props;
    return <CheckoutHeader handleClickCart={handleClickCart} />;
  }

  get checkoutContent() {
    const {
      order,
      checkoutSummaryData,
      continueShopping,
      isOrderUnpaid,
      isDeselectingSeat,
      removingSeatPlacedId,
      checkoutCart,
      handleClickCart,
    } = this.props;
    if (!order && !checkoutSummaryData) {
      return <EmptyCart btnHandler={handleClickCart} />;
    }
    return (
      <div className="sdr-checkout__content">
        <CheckoutSummary
          isDeselectingSeat={isDeselectingSeat}
          {...checkoutSummaryData}
          removingSeatPlacedId={removingSeatPlacedId}
          isOrderProcessed={!!order}
          onClearCart={this.onClearCart}
          isOrderUnpaid={isOrderUnpaid}
          onDeselectSeat={this.onDeselectSeat}
          checkoutSeatBlocks={checkoutCart}
          headerMsg={order ? messages.orderConfirmationHeader : messages.orderSummaryHeader}
        />
        {order ? (
          <OrderConfirmation order={order} continueShopping={continueShopping} />
        ) : (
          <Form onSubmit={this.onFormSubmit} />
        )}
      </div>
    );
  }

  get loadingGear() {
    const {
      ifShowEMVLoading,
      isCheckoutInProgress,
      isEmvCancelInProgress,
      isEmvOperationInProgress,
      isEmvReverseInProgress,
      isOrderDeleteInProgress,
      isEmvPaymentStartInProgress,
    } = this.props;
    if (ifShowEMVLoading) {
      return <EmvTerminal onCancel={this.onCancelEmvPayment} cancelReady={!isEmvPaymentStartInProgress} />;
    }

    if (isEmvReverseInProgress) {
      return (
        <PurchaseLoadingGear
          message={messages.revertingTransaction}
          description={messages.revertingTransactionDescription}
        />
      );
    }

    if (isOrderDeleteInProgress) {
      return <PurchaseLoadingGear message={messages.cancelingOrder} description={messages.cancelingOrderDescription} />;
    }

    if (isCheckoutInProgress || isEmvOperationInProgress || isEmvCancelInProgress) {
      const loadingMessage = !isEmvCancelInProgress ? messages.processingOrder : messages.cancellingPayment;

      return <PurchaseLoadingGear message={loadingMessage} />;
    }
    return null;
  }

  get deletingGear() {
    const { isDeselectingSeat } = this.props;
    if (!isDeselectingSeat) {
      return null;
    }
    return <LoadingComponent largeGear />;
  }

  get innerCheckoutContent() {
    return (
      <div className="sdr-checkout__inner-wrapper">
        {this.checkoutHeader}
        {this.checkoutContent}
      </div>
    );
  }

  render() {
    const { checkoutSummaryData, initCartComponent, showCart } = this.props;
    const checkoutClassName = classNames('sdr-checkout', {
      fastest: true,
      animated: true,
      slideInRight: initCartComponent && showCart,
      slideOutRight: initCartComponent && !showCart,
      'sdr-checkout--detailed': !!checkoutSummaryData,
      'sdr-checkout--hidden': !initCartComponent && !showCart,
    });
    return (
      <div className={checkoutClassName}>
        {this.loadingGear}
        {this.deletingGear}
        {this.innerCheckoutContent}
      </div>
    );
  }
}

Checkout.propTypes = {
  continueShopping: PropTypes.func,
  handleClickCart: PropTypes.func,
  order: PropTypes.object,
  checkoutSummaryData: PropTypes.object,
  ifShowEMVLoading: PropTypes.bool,
  isCheckoutInProgress: PropTypes.bool,
  initCartComponent: PropTypes.bool,
  showCart: PropTypes.bool,
  isOrderUnpaid: PropTypes.bool,
  isDeselectingSeat: PropTypes.bool,
  removingSeatPlacedId: PropTypes.string,
  checkoutCart: PropTypes.object,
  isEmvCancelInProgress: PropTypes.bool,
  showEmvCancel: PropTypes.bool,
  dispatch: PropTypes.func,
  isEmvOperationInProgress: PropTypes.bool,
  isEmvReverseInProgress: PropTypes.bool,
  isOrderDeleteInProgress: PropTypes.bool,
  featureFlags: PropTypes.object,
  currentEventFees: PropTypes.array,
  isEmvPaymentStartInProgress: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const order = selectSuccessOrder(state);
  const cart = selectCartBySeatBlocks(state);
  const orderIdToRetry = selectOrderIdToRetry(state);
  const isDeselectingSeat = selectIsDeselectingSeat(state);
  const removingSeatPlacedId = selectRemovingSeatPlacedId(state);
  const checkoutCart = selectcheckoutCart(state);
  const ifShowEMVLoading = selectIfShowEMVLoading(state);
  const isCheckoutInProgress = selectCheckoutInProgress(state);
  const isPaymentInProgress = selectEmvPaymentInProgress(state);
  const isEmvOperationInProgress = selectEmvOperationInProgress(state);
  const isEmvCancelInProgress = selectEmvPaymentCancelInProgress(state);
  const isEmvReverseInProgress = selectEmvPaymentReverseInProgress(state);
  const isOrderDeleteInProgress = selectIsOrderDeleteInProgress(state);
  const isEmvPaymentStartInProgress = selectEmvPaymentStartInProgress(state);

  return {
    order,
    checkoutSummaryData: cart || (order ? order.ticketsData : null),
    isCheckoutInProgress,
    isOrderUnpaid: !!orderIdToRetry,
    isDeselectingSeat,
    removingSeatPlacedId,
    checkoutCart,
    ifShowEMVLoading,
    isPaymentInProgress,
    isEmvOperationInProgress,
    isEmvCancelInProgress,
    isOrderDeleteInProgress,
    isEmvReverseInProgress,
    isEmvPaymentStartInProgress,
  };
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
